<template>
  <div class="relative">
    <div
      class="grid grid-flow-col auto-cols-fr items-center relative bg-gray-100 rounded-full"
    >
      <div
        v-for="(tab, index) in tabs"
        :key="index"
        class="flex justify-center py-2 px-4 cursor-pointer z-10 text-center text-sm sm:text-base text-gray-700 hover:text-gray-900"
        :class="{
          'text-rendin-500 active': isActiveTab(tab.id),
        }"
        @click="onTabClick(tab.id, index)"
      >
        <div>{{ tab.title }}</div>
      </div>
    </div>
    <span
      class="glider absolute bg-white rounded-full h-full w-56 top-0 bottom-0 shadow-sm transition duration-75 ease-in-out"
      :style="
        'width:' +
        gliderWidth +
        '% ;' +
        'transform: translateX(' +
        gliderTranslatePosition +
        '%)'
      "
    ></span>
  </div>
</template>

<script>
export default {
  name: 'RTabsSwitch',

  props: {
    /**
     * Id of selected tab
     */
    modelValue: {
      type: String,
      required: true,
    },
    /**
     * Array of tab objects with properties `id` (String), `title` (String).
     */
    tabs: {
      type: Array,
      required: true,
    },
  },
  emits: ['update:modelValue'],

  data() {
    return {
      val: this.modelValue,
      tabIndex: 0,
    };
  },

  computed: {
    gliderWidth() {
      return 100 / this.tabs.length;
    },

    gliderTranslatePosition() {
      return this.tabIndex * 100;
    },
  },

  watch: {
    modelValue() {
      this.val = this.modelValue;
      const activeTab = (element) => element.id === this.modelValue;
      this.tabIndex = this.tabIndex >= 0 ? this.tabs.findIndex(activeTab) : 0;
    },
  },

  created() {
    this.val = this.modelValue;
    const activeTab = (element) => element.id === this.modelValue;
    this.tabIndex = this.tabIndex >= 0 ? this.tabs.findIndex(activeTab) : 0;
  },

  methods: {
    isActiveTab(id) {
      return id === this.val;
    },

    onTabClick(value, index) {
      this.tabIndex = index;
      this.$emit('update:modelValue', value);
    },
  },
};
</script>
